// @mui
import React from 'react'
import { styled } from '@mui/material/styles'
import { Container, Box, Typography } from '@mui/material'
// components

import useMediaQuery from "@mui/material/useMediaQuery";
import Page from '../../components/Page'
import About from './About'
import News from './News'
import Publications from './Publications'
import Education from './Education'
import WorkExperience from './WorkExperience'
// import TeachingExperience from './TeachingExperience'
// import Awards from './Awards'
import AcademicServices from './AcademicServices'
// ----------------------------------------------------------------------

const Category = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: '80px',
  marginBottom: '15px',
  fontWeight: 700,
  fontSize: '1.8rem',
}))

// ----------------------------------------------------------------------

export default function CV() {
  const isMobile = useMediaQuery("(max-width: 900px)");

  return (
    <Page title='Beanie' project='beanie'>
      <Container maxWidth='md'>
        <About isMobile={isMobile} />

        <Box>
          <Category>News</Category>
          <News/>
        </Box>

        <Box>
          <Category>Publications</Category>
          <Publications isMobile={isMobile}/>
        </Box>

        <Box>
          <Category>Education</Category>
          <Education/>
        </Box>

        <Box>
          <Category>Work Experience</Category>
          <WorkExperience/>
        </Box>

        <Box sx={{ mb: 8 }}>
          <Category>Academic Services</Category>
          <AcademicServices/>
        </Box>
{/*
        <Box>
          <Category>Teaching Experience</Category>
          <TeachingExperience isMobile={isMobile}/>
        </Box> */}

        {/* <Box sx={{ mb: 10 }}>
          <Category>Awards</Category>
          <Awards/>
        </Box> */}

      </Container>
    </Page>
  )
}
